<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + $t('maintWorkOrder.maintItem')"
    :visible.sync="dialogVisible" :close-on-click-modal="false" width="1400px" top="5vh" append-to-body
    @close="handleClear">
    <vm-table ref="selectTable" v-loading="itemSelectLoading" :filter.sync="search" multi-selection url="maintItem"
      :reserve-selection="true" @row-click="handleRowClick" @select="handleSelectionChange"
      @select-all="handleSelectAll" @get-table-data="setDefaultSelection">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.maintenanceProjectName')">
          <el-input v-model.trim="search.itemName" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('maintWorkOrder.maintArea')">
          <el-select v-model="search.maintArea" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in maintAreaOption" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-button slot="toolbar" @click="handleMultiSelect">{{ $t("common.confirmSelect") }}</el-button>
      <el-button slot="toolbar" @click="handleClear">{{ $t("common.clear") }}</el-button>
      <table-column prop="itemName" :label="$t('maintWorkOrder.maintenanceProjectName')"></table-column>
      <table-column prop="maintContent" :label="$t('maintWorkOrder.maintContent')"></table-column>
      <table-column prop="maintRequire" :label="$t('maintWorkOrder.maintRequire')"></table-column>
      <table-column prop="maintArea" :label="$t('maintWorkOrder.maintArea')">
        <template #default="scope">
          <span v-if="scope.row.maintArea === '机房'">
            {{ $t("maintWorkOrder.maintenanceArea.machineRoom") }}
          </span>
          <span v-else-if="scope.row.maintArea === '轿厢'">
            {{ $t("maintWorkOrder.maintenanceArea.bridge") }}
          </span>
          <span v-else-if="scope.row.maintArea === '层站'">
            {{ $t("maintWorkOrder.maintenanceArea.layer") }}
          </span>
          <span v-else-if="scope.row.maintArea === '井道及底坑'">
            {{ $t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") }}
          </span>
          <span v-else-if="scope.row.maintArea === '扶梯'">
            {{ $t("maintWorkOrder.maintenanceArea.escalator") }}
          </span>
          <span v-else-if="scope.row.maintArea === '其他'">
            {{ $t("maintWorkOrder.maintenanceArea.other") }}
          </span>
        </template>
      </table-column>
      <table-column prop="remark" :label="$t('common.remark')"></table-column>
      <table-column prop="isDefault" :label="$t('common.isDefault')" align="center" width="130px">
        <template #default="scope">
          <el-tag v-if="scope.row.isDefault === 1" type="success">
            {{ $t("common.yes") }}
          </el-tag>
          <el-tag v-else-if="scope.row.isDefault === 0" type="info">
            {{ $t("common.no") }}
          </el-tag>
        </template>
      </table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      itemSelectLoading: true,
      dialogVisible: false,
      multipleSelection: [],
      itemIdList: [],
      maintAreaOption: [
        { value: "机房", label: this.$t("maintWorkOrder.maintenanceArea.machineRoom") },
        { value: "轿厢", label: this.$t("maintWorkOrder.maintenanceArea.bridge") },
        { value: "层站", label: this.$t("maintWorkOrder.maintenanceArea.layer") },
        { value: "井道及底坑", label: this.$t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") },
        { value: "扶梯", label: this.$t("maintWorkOrder.maintenanceArea.escalator") },
        { value: "其他", label: this.$t("maintWorkOrder.maintenanceArea.other") },
      ],
      search: {
        itemName: "",
        maintArea: "",
      },
    };
  },
  methods: {
    open(itemList) {
      this.multipleSelection = JSON.parse(JSON.stringify(itemList));
      this.itemIdList = itemList.map(item => item.id);
      this.dialogVisible = true;
      this.getList(1);
    },
    getList(pageNum) {
      this.$nextTick(() => {
        this.$refs.selectTable.getList(pageNum);
      });
      this.itemSelectLoading = false;
    },
    setDefaultSelection() {
      this.$nextTick(() => {
        this.$refs.selectTable.tableData.forEach(item => {
          if (this.itemIdList.includes(item.id)) {
            this.$refs.selectTable.$refs.elTable.toggleRowSelection(item, true);
          }
        });
      });
    },
    handleSelectionChange(selection, row) {
      if (this.itemIdList.includes(row.id)) {
        this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
        this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
      } else {
        this.multipleSelection.push(row);
        this.itemIdList.push(row.id);
      }
    },
    handleRowClick(row) {
      this.$refs.selectTable.$refs.elTable.toggleRowSelection(row);
      if (this.itemIdList.includes(row.id)) {
        this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
        this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
      } else {
        this.multipleSelection.push(row);
        this.itemIdList.push(row.id);
      }
    },
    handleMultiSelect() {
      this.$emit("multi-select", this.multipleSelection);
      this.dialogVisible = false;
    },
    handleClear() {
      this.$refs.selectTable.$refs.elTable.clearSelection();
      this.multipleSelection = [];
      this.itemIdList = [];
    },
    handleSelectAll(selection) {
      if (selection.length > 0) {
        let selectionList = selection.map(item => item.id);
        this.$refs.selectTable.tableData.forEach(row => {
          if (!selectionList.includes(row.id) && this.itemIdList.includes(row.id)) {
            this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
            this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
          } else {
            if (!this.itemIdList.includes(row.id)) {
              this.multipleSelection.push(row);
              this.itemIdList.push(row.id);
            }
          }
        });
      } else {
        this.$refs.selectTable.tableData.forEach(row => {
          if (this.itemIdList.includes(row.id)) {
            this.multipleSelection.splice(this.itemIdList.indexOf(row.id), 1);
            this.itemIdList.splice(this.itemIdList.indexOf(row.id), 1);
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>