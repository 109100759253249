<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="70%" top="2vh"
    @close="onDialogClose">
    <div v-loading="contentLoading">
      <el-form ref="formValidate" :model="maintType" :label-width="$l('maintWorkOrder.labelWidth', '100px')"
        :rules="ruleValidate">
        <el-form-item prop="maintTypeName" :label="$t('maintWorkOrder.maintTypeName')">
          <el-input v-model.trim="maintType.maintTypeName" :placeholder="$t('common.pleaseEnter')" maxlength="40">
          </el-input>
        </el-form-item>
        <el-form-item prop="elevatorProductType" :label="$t('maintWorkOrder.productTypeName')">
          <el-select style="width: 100%;" v-model="maintType.elevatorProductType" multiple
            :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in productTypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="maintTypeClassify" :label="$t('maintWorkOrder.maintType')">
          <el-select style="width: 100%;" v-model="maintType.maintTypeClassify" :placeholder="$t('common.pleaseSelect')"
            clearable>
            <el-option v-for="item in maintTypeClassify" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="remark" :label="$t('common.remark')">
          <el-input v-model.trim="maintType.remark" type="textarea" :rows="1" :placeholder="$t('common.pleaseEnter')"
            maxlength="255">
          </el-input>
        </el-form-item>
        <el-form-item v-if="currentUserType === 0" prop="isDefault" :label="$t('common.isDefault')">
          <el-switch v-model="maintType.isDefault" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="$refs.selectMaintItem.open(maintItemList)">
          {{ $t("maintWorkOrder.selectMaintItem") }}
        </el-button>
      </div>
      <el-table :data="maintItemList" border stripe style="width: 100%; margin-top: 10px" max-height="400">
        <el-table-column type="index" :label="$t('common.index')" width="60px" align="center"></el-table-column>
        <table-column prop="itemName" :label="$t('maintWorkOrder.maintenanceProjectName')"></table-column>
        <table-column prop="maintContent" :label="$t('maintWorkOrder.maintContent')"></table-column>
        <table-column prop="maintRequire" :label="$t('maintWorkOrder.maintRequire')"></table-column>
        <table-column prop="maintArea" :label="$t('maintWorkOrder.maintArea')"></table-column>
        <table-column prop="remark" :label="$t('common.remark')"></table-column>
        <table-column :label="$t('common.operate')" width="100px" align="center" :tooltip="false">
          <template #default="scope">
            <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
              {{ $t("common.remove") }}
            </el-button>
          </template>
        </table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">{{
        $t("common.save")
      }}</el-button>
    </span>
    <maint-item-select ref="selectMaintItem" @multi-select="handleMultiItemSelect"></maint-item-select>
  </el-dialog>
</template>

<script>
import MaintItemSelect from "@/views/newMaintWorkOrder/maintSetting/maintItemSelect";
import auth from "@/util/auth";

const moduleName = "maintType";
export default {
  components: { MaintItemSelect },
  data() {
    return {
      contentLoading: false,
      submitLoading: false,
      saveDisabled: false,
      dialogVisible: false,
      currentUserType: auth.getUserType(),
      productTypeList: [],
      maintItemList: [],
      maintTypeClassify: [
        { value: "半月保", label: this.$t("maintWorkOrder.type.halfMonth") },
        { value: "季保", label: this.$t("maintWorkOrder.type.month") },
        { value: "半年保", label: this.$t("maintWorkOrder.type.halfYear") },
        { value: "年保", label: this.$t("maintWorkOrder.type.year") },
      ],
      maintType: {
        id: 0,
        maintTypeName: "",
        elevatorProductType: "",
        maintTypeClassify: "",
        remark: "",
        maintItemIdList: [],
        isDefault: 0,
      },
      ruleValidate: {
        maintTypeName: [
          { required: true, message: this.$t("maintWorkOrder.tip.maintTypeName"), trigger: "blur" },
        ],
        elevatorProductType: [
          { required: true, message: this.$t("maintWorkOrder.tip.elevatorProductType"), trigger: "blur" },
        ],
        maintTypeClassify: [
          { required: true, message: this.$t("maintWorkOrder.tip.maintTypeClassify"), trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    title() {
      return (this.maintType.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " +
        this.$t("maintWorkOrder.maintType");
    },
  },
  methods: {
    open(id) {
      this.maintType.id = id;
      this.dialogVisible = true;
      this.getElevatorProductTypeList();
      if (id > 0) {
        this.getData();
      }
    },
    getData() {
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$api.getById(moduleName, this.maintType.id).then(res => {
        this.maintType = res.data;
        this.maintType.elevatorProductType = this.maintType.elevatorProductType.split(",");
        this.getMaintItemList();
        this.contentLoading = false;
        this.saveDisabled = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    getMaintItemList() {
      let param = {
        idList: this.maintType.maintItemIdList.toString(),
      };
      this.$api.getData("maintItem/type", param).then(res => {
        this.maintItemList = res.data;
      }).catch(error => {
        if (error.response) {
          this.$message.error(this.$t("maintWorkOrder.tip.getMaintTypeItemError") + "," + error.response.data.message);
        }
      });
    },
    getElevatorProductTypeList() {
      this.$api.getList("elevatorProductTypes").then(response => {
        this.productTypeList = [];
        for (let productType of response.data) {
          let item = "";
          if (this.$i18n.isCn) {
            item = { value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeName };
          } else {
            item = { value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeNameEn };
          }
          this.productTypeList.push(item);
        }
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("maintWorkOrder.tip.getElevatorProductTypeError") + "," + error.response.data.message);
        }
      });
    },
    onDialogClose() {
      this.saveDisabled = false;
      this.$refs.formValidate.resetFields();
      if (this.$refs.selectMaintItem.$refs.selectTable) {
        this.$refs.selectMaintItem.$refs.selectTable.$refs.elTable.clearSelection();
        this.$refs.selectMaintItem.$refs.selectTable.handleClearClick();
        this.$refs.selectMaintItem.flag = 0;
      }
      this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          if (this.maintType.id === 0) {
            this.maintType.id = null;
          }
          this.maintType.elevatorProductType = this.maintType.elevatorProductType.toString();
          this.maintType.maintItemIdList = this.maintItemList.map(item => item.id);
          this.submitLoading = true;
          this.$api.save(moduleName, this.maintType).then(() => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$refs.selectMaintItem.$refs.selectTable.$refs.elTable.clearSelection();
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
    handleMultiItemSelect(rows) {
      this.maintItemList = rows;
    },
    deleteRow(index) {
      this.maintItemList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
